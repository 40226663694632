var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_vm._v(" 자주 묻는 질문 "),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","to":"/faq/create"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" 등록 ")],1)],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":_vm.listFaq,"items-per-page":_vm.offset,"hide-default-footer":"","expanded":_vm.expanded},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.day(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.day(item.updatedAt))+" ")]}},{key:"item.showFlag",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.showFlag ? '노출' : '비노출')+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-5",attrs:{"depressed":"","color":"green","dark":"","to":'/faq/' + item.id}},[_vm._v(" 수정 ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":"1"}}),_c('td',{attrs:{"colspan":headers.length - 1}},[_c('VueMarkdown',{staticClass:"mt-4",attrs:{"source":item.answer}})],1)]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-container',{staticClass:"row",attrs:{"fluid":"","no-gutters":""}},[_c('v-btn',{staticClass:"mr-3",attrs:{"icon":""},on:{"click":_vm.deleteFaq}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-select',{attrs:{"color":"#ffd600","items":[5, 10, 15, 25, 50, 100],"outlined":"","dense":"","hide-details":""},on:{"change":_vm.changeOffset},model:{value:(_vm.offset),callback:function ($$v) {_vm.offset=$$v},expression:"offset"}})],1),_c('v-spacer'),_c('v-col',[_c('v-pagination',{attrs:{"total-visible":"13","length":_vm.pageNumber,"circle":"","color":"#ffd600"},on:{"input":_vm.getListFaq},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }