<template>
  <v-container fluid>
    <v-card-title>
      자주 묻는 질문
      <v-spacer />
      <v-btn outlined to="/faq/create">
        <v-icon left>mdi-plus</v-icon>
        등록
      </v-btn>
    </v-card-title>
    <v-card outlined>
      <v-data-table
        v-model="selected"
        show-select
        :headers="headers"
        :items="listFaq"
        :items-per-page="offset"
        hide-default-footer
        :expanded="expanded"
        @click:row="clickRow"
      >
        <template v-slot:item.createdAt="{ item }">
          {{ day(item.createdAt) }}
        </template>
        <template v-slot:item.updatedAt="{ item }">
          {{ day(item.updatedAt) }}
        </template>
        <template v-slot:item.showFlag="{ item }">
          {{ item.showFlag ? '노출' : '비노출' }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn depressed color="green" dark class="mr-5" :to="'/faq/' + item.id">
            수정
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td colspan="1" />
          <td :colspan="headers.length - 1">
            <VueMarkdown class="mt-4" :source="item.answer" />
          </td>
        </template>
        <template v-slot:footer>
          <v-divider />
          <v-container fluid class="row" no-gutters>
            <v-btn icon class="mr-3" @click="deleteFaq">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-col cols="1">
              <v-select v-model="offset" color="#ffd600" :items="[5, 10, 15, 25, 50, 100]" outlined dense hide-details @change="changeOffset" />
            </v-col>
            <v-spacer />
            <v-col>
              <v-pagination v-model="page" total-visible="13" :length="pageNumber" circle color="#ffd600" @input="getListFaq" />
            </v-col>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent, reactive, onBeforeMount, toRefs } from '@vue/composition-api'
import VueMarkdown from 'vue-markdown'
import { day } from '@/helper'

export default defineComponent({
  components: {
    VueMarkdown,
  },

  setup(props, { root }) {
    const state = reactive({
      headers: [
        { text: 'ID', value: 'id' },
        { text: '질문', value: 'question' },
        { text: '작성일시', value: 'createdAt' },
        { text: '수정일시', value: 'updatedAt' },
        { text: '노출여부', value: 'showFlag' },
        { text: '조회수', value: 'views' },
        { text: null, value: 'action' },
      ],
      selected: [],
      listFaq: [],
      pageNumber: null,
      page: 1,
      offset: 10,
      expanded: [],
    })

    const clickRow = (value) => {
      const index = state.expanded.indexOf(value)
      if (index === -1) {
        state.expanded = [value]
      } else {
        state.expanded = []
      }
    }

    const getListFaq = async () => {
      try {
        const listFaq = await root.$store.dispatch('faq/getListFaq', {
          offset: state.offset,
          pageNumber: state.page,
        })

        state.listFaq = listFaq.data
        state.pageNumber = Math.ceil(listFaq.totalCount / state.offset)
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    const deleteFaq = async () => {
      if (!confirm(`${state.selected.length}개의 FAQ를 삭제합니다.`)) return

      for (const e of state.selected) {
        await root.$store.dispatch('faq/deleteFaq', {
          id: Number(e.id),
        })
      }

      getListFaq()

      alert('삭제 완료!')
    }

    const changeOffset = () => {
      state.page = 1

      getListFaq()
    }

    onBeforeMount(() => {
      getListFaq()
    })

    return {
      ...toRefs(state),
      clickRow,
      changeOffset,
      getListFaq,
      deleteFaq,
      day,
    }
  },
})
</script>
